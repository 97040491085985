import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function getLocale() {
  // // 讀取cookie存入的當前語言
  const cookieLanguage = localStorage.getItem('ddpw:locale');
  // 如果有返回當前語言
  if (cookieLanguage) {
    return cookieLanguage;
  }
  // 如果没有，獲取系統語言
  let browserLanguage = window.navigator.language;
  let language = browserLanguage.toLowerCase();
  // 系統語言編碼和APP語言編碼不同時，如何轉換？如en-us/en-eg需統一轉成en
  if (language.startsWith('en-')) {
    language = 'en';
  }
  if (language === 'zh-tw') {
    language = 'tw';
  }
  // 獲取messages 語言，遍歷
  const locales = Object.keys(messages);
  for (const locale of locales) {
    // 如果message 包里面有系统语言返回
    if (language.indexOf(locale) > -1) {
      localStorage.setItem('ddpw:locale', locale);
      return locale;
    }
  }
  localStorage.setItem('ddpw:locale', 'tw');
  // 預設语言
  return 'tw';
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

let messages = loadLocaleMessages();

export default new VueI18n({
  locale: getLocale(),  // process.env.VUE_APP_I18N_LOCALE,
  // locale: getLocale(),
  fallbackLocale: getLocale(), // process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages
})

