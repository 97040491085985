import i18n from '@/i18n'

export function getPaymentImage(service, type) {
  let icon = null;
  let iconW = null;
  let label = null;
  let method = `${service}-${type}`;
  // console.log('getPaymentImage:' + method)
  // service: taiwanpay type: tcb
  // service: pxpayplus type: pxpayplus
  // service: pluspay type: pluspay
  switch (method) {
    case "cardpay-apple":
    case "tappay-apple":
      // icon = require('@/assets/img/p_apple.png');
      icon = require("@/assets/icon/pApple.svg");
      iconW = require("@/assets/icon/pAppleW.svg");
      label = 'Apple Pay';
      break;
    case "cardpay-google":
    case "tappay-google":
      icon = require("@/assets/icon/pGoogle.svg");
      iconW = require("@/assets/icon/pGoogleW.svg");
      label = 'Google Pay';
      break;
    case "cardpay-line":
    case "tappay-line":
    case "linepay-linepay":
      icon = require("@/assets/icon/pLine.svg");
      iconW = require("@/assets/icon/pLineW.svg");
      label = "LINE Pay";
      break;
    case "easywallet-easywallet":
      icon = require("@/assets/icon/pEasy.svg");
      iconW = require("@/assets/icon/pEasyW.svg");
      label = i18n.t('paymentMethods.easywallet');
      break;
    case "tappay-jko":
    case "jko-jko":
      icon = require("@/assets/icon/pJko.svg");
      iconW = require("@/assets/icon/pJkoW.svg");
      label = i18n.t('paymentMethods.jko');
      break;
    case "taiwanpay-twqrp":
    case "taiwanpay-emvco":
    case "taiwanpay-tcb":  
      icon = require("@/assets/icon/pTaiwan.png");
      iconW = require("@/assets/icon/pTaiwan.png");
      label = i18n.t('paymentMethods.taiwanpay');
      break;
    case "tappay-credit":
    case "tappay-direct":
    case "cardpay-credit":
      icon = require("@/assets/icon/pCredit.svg");
      iconW = require("@/assets/icon/pCreditW.svg");
      label = i18n.t('paymentMethods.credit');
      break;
    case "icashpay-icashpay":
      icon = require("@/assets/icon/icash-h.svg");
      iconW = require("@/assets/icon/icash.svg");
      label = i18n.t('paymentMethods.icashpay');
      break;
    case "fastek-gcash-ewallet":
      icon = require("@/assets/icon/pGcash.png");
      iconW = require("@/assets/icon/pGcashW.svg");
      label = i18n.t('paymentMethods.gcash');
      break;
    case "fastek-maya":
    case "fastek-maya-web":
      icon = require("@/assets/icon/pMaya.svg");
      iconW = require("@/assets/icon/pMaya.svg");
      label = i18n.t('paymentMethods.maya');
      break;
    case "pxpayplus-pxpayplus":
      icon = require("@/assets/icon/pPx.png");
      iconW = require("@/assets/icon/pPxW.png");
      label = i18n.t('paymentMethods.pxpayplus');
      break;
    case "pluspay-pluspay":
      icon = require("@/assets/icon/pPlus.png");
      iconW = require("@/assets/icon/pPlusW.png");
      label = i18n.t('paymentMethods.pluspay');
      break;
    case "offline-offline": //現場支付
      icon = null;
      iconW = null;
      label = i18n.t('paymentMethods.offline');
      break;
    default:
      icon = null;
      break;
  }
  return { icon, iconW, label };
}

export function getCreditCardImage(type) {
  let icon = null;

  switch (type) {
    case "visa":
    case "V":
      icon = require("@/assets/icon/pVisa.svg");
      break;
    case "master":
    case "M":
      icon = require("@/assets/icon/pMaster.svg");
      break;
    case "jcb":
    case "J":
      icon = require("@/assets/icon/pJcb.svg");
      break;
    default:
      icon = require("@/assets/icon/pCredit.svg");
      break;
  }
  return icon;
}

export function formatCurrency(number, options) {
  const locale = window.navigator.language;
  const _currency = options?.currency || "TWD";
  // let config = {
  //   style: "currency",
  //   currency: _currency,
  //   currencyDisplay: 'narrowSymbol',
  //   trailingZeroDisplay: 'stripIfInteger'
  // };
  
  const _display = options?.currencyDisplay && ["code", "name", "symbol"].includes(options.currencyDisplay) ?  options?.currencyDisplay : "symbol";
  const config = Object({
    style: "currency",
    currency: _currency,
    currencyDisplay: _display || "symbol",
    useGrouping: true,
   // calculateReservced: 0
  }, options);
  if (["TWD"].includes(_currency)) {
    config.trailingZeroDisplay = 'stripIfInteger';

    // config.minimumFractionDigits = 0;
    // config.maximumFractionDigits = 0;
  }
  return new Intl.NumberFormat(locale, config).format(number);
}
