
const state = () => ({
  paymentList: [],
  selectedPaymentOrder: null,
  firestoreInfo: null,
});

const getters = {
  getPaymentList: (state) => state.paymentList,
  getSelectedPaymentOrder: (state) => state.selectedPaymentOrder,
  getFirestoreInfo: (state) => state.firestoreInfo,
};

const mutations = {
  setPaymentList(state, v) {
    state.paymentList = v;
  },
  setSelectedPaymentOrder(state, v) {
    state.selectedPaymentOrder = v;
  },
  setFirestoreInfo(state, v) {
    state.firestoreInfo = v;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

