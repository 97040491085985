
import store from "@/store";

export default {
  data() {
    return {};
  },

  created() {
  },

  methods: {
    notEmptyObject(someObject){
      return Object.keys(someObject).length
    },
    ddauth() {
      const qcsso = window.qcsso;
      const appId = store.state.ssoAppId;
      //orders?state=token -> /orders/token
      const redirectUrl = window.location;
      // const redirectUrl = window.location.origin;
      console.log('[SSO] redirect url:' + redirectUrl);
      qcsso
        .init({
          appId
        })
        .then(() => {
          // 系統將自動跳出iframe進行驗證
          if (!qcsso.isLoggedIn()) {
            qcsso.ddauth({ redirectUrl }) // 開始進行ddauth認證
              .then(() => {
                console.log('ddauth done');
                this.getUserProfile();
              })
          } 
        })
        .catch((e) => {
          console.error("[signin] SSO init exception:", e);
        });
    },
    signin() {
      const qcsso = window.qcsso;
      // const appId = process.env.VUE_APP_ID;
      const appId = store.state.ssoAppId;
      // 設定redirect url
      // 會根據是是有付款金額決定到哪一頁
      const redirectUrl = window.location;
      console.log("[mixins signin] signin redirect url:" + redirectUrl);
      // if (qcsso.isLoggedIn()) {
      //   // this.getUserProfile();
      // } else {
      //   qcsso.login({ redirectUrl });
      // }
      qcsso
        .init({
          appId
        })
        .then(() => {
          if (qcsso.isLoggedIn()) {
            // this.getUserProfile();
          } else {
            qcsso.login({ redirectUrl });
          }
        })
        .catch((e) => {
          console.error("[signin] SSO init exception:", e);
        });
    },
    signout(token, redirectUrl) {
      console.log("[mixins signout] start logout" );
      const qcsso = window.qcsso;
      // let location = window.location.href;
      // const temp = location.split('/');
      // const token = temp[temp.length-1];
      // console.log(window.location)
      const _redirectUrl = redirectUrl || window.location.origin + '/orders';
      // const appId = store.state.ssoAppId || process.env.VUE_APP_ID;
      const appId = store.state.ssoAppId;
      qcsso
      .init({
        appId
      })
      .then(() => {
        qcsso
        .logout({ logoutRedirectUri: _redirectUrl, state: token })
        .then(() => {
          console.log("[mixins signout] logout succss");
          store.commit('updateIsLoggedIn', false);
          store.commit('updateShowLogout', false)
        })
        .catch((e) => {
          console.error(e);
        });
      })
      .catch((e) => {
        console.error("[mixins signout] SSO init exception:", e);
      });

     
    },
    getUserProfile() {
      console.log("[mixins] start get user profilie");
      try {
        const qcsso = window.qcsso;
        // const token = qcsso.getAccessToken();
        qcsso
          .getProfile()
          .then(function (user) {
            console.log("[mixins] done get user profile", user);
            store.commit("updateUser", user);
            store.commit("updateIsLoggedIn", true);
          
            // alert('登入成功');
          })
          .catch(function (e) {
            console.log(e);
          })
          .finally(() => {});
      } catch (e) {
        console.log("[mixins] getUserProfile error:", e)
      }
    },
  }
}