<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <Loading :spin="!isRouterResolved" />

    <router-view />
    <notifications
      group="noti-popup"
      position="top center"
      :max="1"
      width="96%"
      :duration="3000"
    >
    </notifications>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    Loading
  },
  computed: {
    ...mapState({
      isRouterResolved: (state) => state.isRouterResolved,
    })
  },
  mounted() {
    this.disableTapToZoom();
  },
  methods: {
    disableTapToZoom() {
      console.log('disableTapToZoom');
      let lastTouchEndTime = 0;
      document.addEventListener(
        "touchend",
        (event) => {
          const now = new Date().getTime();
          if (now - lastTouchEndTime <= 300) {
            event.preventDefault();
          }
          lastTouchEndTime = now;
        },
        false
      );
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    },
  }
}
// @ is an alias to /src
// import { mapState } from 'vuex';
// import Loading from "@/components/Loading.vue";
// export default {
//   components: { Loading },
//   computed: {
//     ...mapState({
//       isRouterResolved: (state) => state.isRouterResolved,
//     }),
//   }
// };
</script>

<style>
</style>
