<template>
  <div>
    <!-- <pre>{{order}}</pre> -->
    <Login v-if="isSSOReady" />
    <!-- <router-view name="Header"></router-view> -->
    <router-view></router-view>
    <!-- <router-view :routeTo="routeTo" name="Footer"></router-view> -->
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
import { mapState, mapGetters } from "vuex";
// import { fetchStoreServices } from "@/apis/fetchData";

export default {
  name: "AppLayout",
  components: { Login },
  // beforeRouteEnter(to, from, next) {
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   // console.log(to, from);
  //   if (to.meta.orderRequired) {
  //     // 需要透過token 取得訂單資訊者，取完訂單後，要取得單店sso app ID
  //     next((vm) => {
  //       let that = vm;
  //       const shopId = vm.shopId;

  //       fetchStoreServices(shopId)
  //         .then((res) => {
  //           console.log("[AppLayout] fetchStoreServices done:", res);
  //           const appId =
  //             res.data.sso && res.data.sso.appId ? res.data.sso.appId : null;
  //           that.$store.commit("updateSSOAppId", appId);
  //           that.$store.commit("updateShopSettings", res.data);
  //           if (res.data && res.data.points) {
  //             const pointsUniqueByKey = [
  //               ...new Map(
  //                 res.data.points.map((item) => [item['pointCode'], item])
  //               ).values(),
  //             ];
  //             that.$store.commit("updateSupportedPoints", pointsUniqueByKey);
  //           } else {
  //             that.$store.commit("updateSupportedPoints", []);
  //           }
  //         })
  //         .catch((e) => {
  //           console.error("fetchStoreServices failed:", e);
  //           that.$store.commit("updateSSOAppId", null);
  //           that.$store.commit("updateSupportedPoints", []);
  //         })
  //         .finally(() => {
  //           next();
  //         });
  //     });
  //   } else {
  //     next();
  //   }
  // },
  // async beforeRouteEnter(to, from, next) {
  //   // react to route changes...
  //   console.log('order token:' + to.params.token, from.params.token);
  //   if (to.params.token) {
  //     // 取得訂單資訊
  //     next(vm => {
  //       // vm.$store.dispatch('loadLeaderboard');
  //       fetchOrderInfo(to.params.token)
  //         .then((res) => {
  //           console.log("fetchOrderInfo done", res);
  //           vm.$store.commit('updateOrderInfo', res.data);
  //           next();
  //           vm.$nextTick();
  //         })
  //         .catch((e) => {
  //           console.error("fetchOrderInfo failed", e);
  //           vm.$notify({
  //             group: 'noti-popup',
  //             title: 'Error',
  //             text: 'Failed to get order information!'
  //           })
  //           next("/error");
  //         });

  //     });
  //   } else {
  //     // 跳轉錯誤頁
  //     console.log('redirect to error page')
  //     next("/error");
  //   }
  //   // this.userData = await fetchUser(to.params.token)
  // },
  data() {
    return {
      routeTo: this.$route.path,
      // accentColor: 'green',
      // c1Color: '#EF6079',
      // c2Color: '#F3CBC7'
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
      order: (state) => state.orderInfo,
      ssoAPPId: (state) => state.ssoAPPId,
      isSSOReady: (state) => state.isSSOReady,
    }),
    ...mapGetters({
      shopId: "getShopId",
      thirdPartyMember: 'getThirdPartyMemberShip',
    }),
    token() {
      return this.$route.params.token;
    },
  },
  watch: {
    isLoggedIn: {
      handler(newVal) {
        if (newVal) {
          this.gotoNext();
        }
      },
    },
    // "thirdPartyMember.partnerCode": {
    //   handler(newVal) {
    //     console.log('watch thirdPartyMember partnerCode:', newVal);
    //     if (newVal) {
    //       console.log('init portal with partner code');
    //       this.$store.dispatch("portal/init");
    //     }
    //   },
    // }
    // shopId: {
    //   handler(newVal) {
    //     if (newVal) {
    //       this.getSSOAppId();
    //     }
    //   }
    // }
  },
  mounted() {
    console.log(
      "[mounted] applayout: ",
      process.env.VUE_APP_HOST,
      `${process.env.BASE_URL}`,
      process.env.VUE_APP_ENV
    );
    // window.addEventListener("beforeunload", this.onUnload);
    // this.disableTapToZoom();
    // 設定主色系
    // const docRoot = document.documentElement;
    // docRoot.style.setProperty("--k1", this.accentColor);
    // docRoot.style.setProperty("--c1", this.c1Color);
    // docRoot.style.setProperty("--c2", this.c2Color);

    // window.addEventListener('beforeunload', this.leaving)
    // if (this.shopId) {
    //   this.getShopSettings();
    // }
  },
  // beforeDestroy() {
  //   window.removeEventListener("beforeunload", this.onUnload);
  // },
  methods: {
    // leaving(e) {
    //   // console.log('leaving', e);
    //   localStorage.removeItem('ddpw:amount');
    // },
    // onUnload(event) {
    //   console.log('remove x-api-authorization');
    //   localStorage.removeItem("ddpw:x-api-authorization");
    // },
    disableTapToZoom() {
      console.log("disableTapToZoom");
      const noZoomText = document.body;

      noZoomText.addEventListener(
        "touchstart",
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    },
    gotoNext() {
      // 根據訂單狀態決定導到哪裡
      const isOrderRequired = this.$route.meta.orderRequired;
      const routeName = this.$route.name;
      const amount = localStorage.getItem("ddpw:amount")
        ? Number(localStorage.getItem("ddpw:amount"))
        : 0;
      console.log("isOrderRequired:" + isOrderRequired);
      if (isOrderRequired) {
        // 需要token取得訂單資訊者
        if (
          (this.order && this.order.orderInfo && this.order.orderInfo.amount) ||
          (this.order && this.order.mode === "cd" && amount)
        ) {
          if (routeName !== "Order") {
            this.$router.push({ name: "Order", params: { token: this.token }, query: this.$route.query });
          }
        } else {
          if (routeName !== "Payment") {
            // this.$store.commit('updateAmount', 0)
            this.$router.push({
              name: "Payment",
              params: { token: this.token },
              query: this.$route.query
            });
          }
        }
      }
    },
    // getShopSettings() {
    //   if (!this.shopId) return;
    //   console.log("[AppLayout] getShopSettings");
    //   fetchStoreServices(this.shopId)
    //     .then((res) => {
    //       console.log(
    //         "[AppLayout] fetchStoreServices done:" + JSON.stringify(res.data)
    //       );
    //       const appId =
    //         res.data.sso && res.data.sso.appId ? res.data.sso.appId : null;
    //       this.$store.commit("updateSSOAppId", appId);
    //       if (res.data && res.data.points) {
    //         const pointsUniqueByKey = [
    //           ...new Map(
    //             res.data.points.map((item) => [item["pointCode"], item])
    //           ).values(),
    //         ];
    //         this.$store.commit("updateSupportedPoints", pointsUniqueByKey);
    //       } else {
    //         this.$store.commit("updateSupportedPoints", []);
    //       }
    //     })
    //     .catch((e) => {
    //       console.error("fetchStoreServices failed:", e);
    //       this.$store.commit("updateSSOAppId", null);
    //       this.$store.commit("updateSupportedPoints", []);
    //     });
    // },
  },
};
</script>

<style src="@/assets/css/index.css"></style>
<!--
<style>
:root {
  --k1: #EE6C5F;
  --c1: #EF6079;
  --c2: #F3CBC7;
}
</style> -->
<!-- <style src="@/assets/css/unique.css"></style> -->
